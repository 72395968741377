import React, { useContext } from "react"
import { Link } from "gatsby"
import {
  Button,
  Box,
  Heading,
  Grid,
  FormField,
  Form,
  TextArea,
  TextInput,
  Paragraph,
  ResponsiveContext,
} from "grommet"
import { MailOption, StatusGood } from "grommet-icons"

const defaultValue = {
  name: "",
  email: "",
  url: "",
  about: "",
}

const JoinUsForm = () => {
  const size = useContext(ResponsiveContext)
  const [value, setValue] = React.useState(defaultValue)
  const [joinUsFormSubmitted, setJoinUsFormSubmitted] = React.useState(false)

  const formSubmit = event => {
    const submit = `&submit=SUBMIT`
    const formResponse = `formResponse`
    const makeUrl = `https://docs.google.com/forms/d/e/1FAIpQLScWZ37qJdPbng9QcdVt9Kt8D4S98G0tBfxN8wKeFzx6369WAQ/${formResponse}?usp=pp_url&entry.1149582078=${event.value.name}&entry.1671872752=${event.value.email}&entry.1245773072=${event.value.url}&entry.1934903889=${event.value.about}${submit}`

    fetch(makeUrl, {
      method: "GET", // or 'PUT'
      mode: "no-cors",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then()
      .then(response => {
        setJoinUsFormSubmitted(true)
      })
  }

  const JoinUsForm = (
    <Form
      value={value}
      onChange={nextValue => {
        setValue(nextValue)
      }}
      onSubmit={event => formSubmit(event)}
    >
      <Grid
        columns={{
          count: size === "small" ? "fit" : 2,
          size: size === "small" ? "100%" : ["auto", "auto"],
        }}
        gap="small"
      >
        <FormField
          required
          name="name"
          label="Name"
          htmlFor="name"
          id="name"
          margin={{ vertical: "none" }}
          component={TextInput}
          placeholder="type here"
        />
        <FormField
          margin={{ vertical: "none" }}
          htmlFor="email"
          label="Email address"
        >
          <TextInput
            required
            id="email"
            type="email"
            name="email"
            label="Email address"
            icon={<MailOption />}
          />
        </FormField>
      </Grid>

      <FormField
        name="url"
        htmlFor="url"
        id="url"
        label="Link"
        help={
          <Paragraph margin={{ top: "none", bottom: "small" }} color="dark-3">
            For example, a website or LinkedIn profile
          </Paragraph>
        }
        margin={{ top: "small" }}
        component={TextInput}
        placeholder="type here"
      />

      <FormField
        margin={{ top: "small" }}
        name="about"
        help={
          <Paragraph margin={{ top: "none", bottom: "small" }} color="dark-3">
            Let us know your availability, specialisms, interests and what you
            want to get from working with us.
          </Paragraph>
        }
        component={TextArea}
        label="A bit about you"
        placeholder="type here"
      />
      <Paragraph>
        Please read our <Link to="/privacy">Privacy Policy</Link> to see how we
        use your personal information.
      </Paragraph>
      <Button size="large" type="submit" label="Submit" primary color="brand" />
    </Form>
  )

  const JoinUsFormSucess = (
    <Box
      border={{
        color: "brand",
        size: "medium",
        style: "solid",
        side: "all",
      }}
    >
      <Box width="auto" alignSelf="start" background="brand" pad="small">
        <StatusGood color="black" size="large" />
      </Box>
      <Box pad="large">
        <Heading margin={{ vertical: "none" }}>Thank you</Heading>
        <Paragraph>
          Great that you want to be part of our community, we’ll be in touch if
          we can work together!
        </Paragraph>
      </Box>
    </Box>
  )

  return <Box fill>{joinUsFormSubmitted ? JoinUsFormSucess : JoinUsForm}</Box>
}

export default JoinUsForm
