import React from "react"
import { Box, Heading, Paragraph } from "grommet"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Seo from "../components/seo"
import JoinUsForm from "../components/joinUsForm"
import { ResponsiveContext } from "grommet"
import Layout from "../components/layout"

export default function JoinUsPage(data) {
  return (
    <Layout>
      <Seo title="Join us" />
      <ResponsiveContext.Consumer>
        {size => (
          <Box pad={{ horizontal: "medium" }} margin={{vertical: "xlarge"}}>
            <Box width="xlarge" alignSelf="center" align="start">
              <Heading
                className="hero"
                margin={{ top: "xlarge", bottom: "xsmall" }}
                level="2"
                size="xlarge"
              >
                Join us
              </Heading>
              <Paragraph size="large">
                We’re always taking on new problems, projects and partnerships —
                part of the beauty of the work we do is the diversity of
                problems and people we work with. It’s never boring.
              </Paragraph>
              <Heading size="medium" level={3} margin={{ bottom: "medium" }}>
                What we’re looking for
              </Heading>
              <Box direction="row-responsive" gap="large">
                <Paragraph size="large" margin={{ top: "none" }}>
                  We like people, all kinds - people with a sense of adventure,
                  people who are always open, people who want to learn, people
                  who listen and empathise with the needs of others. We’re not
                  bothered about MBAs, we want to know if you have passion to
                  make the world a better place and the drive to make it happen.
                </Paragraph>

                <Paragraph size="large" margin={{ top: "none" }}>
                  The business has grown out of friendship and our common
                  purpose. If you can get onboard with those two things, then we
                  think you’ll be great.
                </Paragraph>
              </Box>
              <Box margin={{ vertical: "large" }} fill>
                <Img
                  objectFit="cover"
                  alt={data.data.file.name}
                  fluid={data.data.file.childImageSharp.fluid}
                />
              </Box>
              <Box size="large" alignSelf="center">
                <Heading
                  textAlign="center"
                  size="medium"
                  level={2}
                  margin={{ bottom: "none" }}
                >
                  About you
                </Heading>

                <Paragraph
                  textAlign="center"
                  size="large"
                  margin={{ bottom: "large" }}
                >
                  Get in touch, we’d love to hear from you.
                </Paragraph>
                <JoinUsForm />
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  )
}
export const query = graphql`
  query JoinQuery {
    file(relativePath: { eq: "static/images/lego.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
